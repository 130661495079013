body,
html {
  background: linear-gradient(#b025b5 0%, #3923ae);
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #0c70b4;
  color: #fff;
  font: normal 400 18px "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.card {
  /*
  -webkit-animation: cardEnter 0.75s ease-in-out 0.5s;
  animation: cardEnter 0.75s ease-in-out 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  */
  font-size: initial;
  text-align: left;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin: 5px auto;
  opacity: 1;
  color: #000;
  line-height: 49px;
}

/* Radio buttons */
input[type="radio"] {
  font-size: 60px;
}

.feedback-button {
  border-color: #fff;
  width: 100%;
  display: inline-block;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: normal;
  --bs-btn-line-height: 1.42857143;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  cursor: pointer;
  margin: 0 2% 0 0;
  padding: 12px 22px;
  border: none;
  font: normal normal bold 1.6em / normal "Syncopate", Helvetica, sans-serif;
  color: #ecf0f1;
  background: linear-gradient(45deg, #c024b6 0%, #3023ae 100%);
  background-clip: border-box;
  background-size: auto auto;
}

.feedback-button:active {
  top: 5px;
  background: -webkit-linear-gradient(-90deg, #ff8583 0, #ff5350 100%);
  background: -moz-linear-gradient(180deg, #ff8583 0, #ff5350 100%);
  background: linear-gradient(180deg, #ff8583 0, #ff5350 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 0 5px 0 0 rgba(178, 49, 49, 1);
  box-shadow: 0 5px 0 0 rgba(178, 49, 49, 1);
}

.uName {
  font-size: 20px;
  padding: 20px;
}
